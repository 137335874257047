// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json__Decode from "../../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as LocalStorage$Showside from "../../utils/LocalStorage.bs.js";
import * as NavMenuConfig$Showside from "../../types/NavMenuConfig.bs.js";
import * as WipeDOMEvents$Showside from "../../utils/WipeDOMEvents.bs.js";
function getPreloaded(param) {
  return Belt_Option.getWithDefault(window.__makerNavMenuPreloaded, false);
}
function setPreloaded(value) {
  window.__makerNavMenuPreloaded = value;
}
function getMenuOpened(param) {
  return Belt_Option.getWithDefault(window.__makerNavMenuOpened, false);
}
function setMenuOpened(value) {
  window.__makerNavMenuOpened = value;
}
function getSettings(fromConfig, param) {
  var fromLocalStorage = Belt_Option.flatMap(LocalStorage$Showside.get("navMenuConfig"), function (value) {
    try {
      var settings = Json__Decode.decodeValue(JSON.parse(value), NavMenuConfig$Showside.fromJson);
      if (settings.TAG === /* Ok */0) {
        return settings._0;
      } else {
        return;
      }
    } catch (e) {
      return;
    }
  });
  var settings = Belt_Option.orElse(fromConfig, fromLocalStorage);
  if (Caml_obj.notequal(fromConfig, fromLocalStorage) && fromConfig !== undefined) {
    var encodedSettings = Belt_Option.map(settings, function (settings) {
      return JSON.stringify(NavMenuConfig$Showside.toJson(settings));
    });
    if (encodedSettings !== undefined) {
      LocalStorage$Showside.set("navMenuConfig", encodedSettings);
    }
  }
  return settings;
}
function preloadCustomCSS(settings) {
  var id = "maker-smartnav-menu-preload-css";
  var existingElement = document.getElementById(id);
  var element;
  if (existingElement == null) {
    var element$1 = document.createElement("style");
    element$1.setAttribute("id", id);
    Belt_Option.forEach(Belt_Array.get(Array.prototype.slice.call(document.getElementsByTagName("body")), 0), function (el) {
      el.appendChild(element$1);
    });
    element = element$1;
  } else {
    element = existingElement;
  }
  var css = settings !== undefined ? Belt_Option.getWithDefault(settings.injectCSS, "") : "";
  element.innerHTML = css;
}
function preloadCustomJS(settings) {
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("body")), function (body) {
    var script = document.createElement("script");
    script.innerHTML = Belt_Option.getWithDefault(Belt_Option.flatMap(settings, function (settings) {
      return settings.injectJS;
    }), "");
    body.appendChild(script);
  });
}
function preloadEventListeners(settings) {
  var element = Belt_Option.flatMap(Belt_Option.flatMap(settings, function (settings) {
    return settings.hamburgerTriggerButtonTarget;
  }), function (target) {
    return Caml_option.nullable_to_opt(document.querySelector(target));
  });
  Belt_Option.forEach(Belt_Option.map(element, WipeDOMEvents$Showside.wipeDOMEvents), function (el) {
    el.addEventListener("click", function (e) {
      e.stopImmediatePropagation();
      window.__makerNavMenuOpened = true;
    });
  });
}
function preload(settings) {
  if (Belt_Option.getWithDefault(window.__makerNavMenuPreloaded, false) === false) {
    window.__makerNavMenuPreloaded = true;
    preloadCustomJS(settings);
    preloadCustomCSS(settings);
    return preloadEventListeners(settings);
  }
}
export { getPreloaded, setPreloaded, getMenuOpened, setMenuOpened, getSettings, preloadCustomCSS, preloadCustomJS, preloadEventListeners, preload };
/* Json__Decode Not a pure module */