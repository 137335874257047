// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Account_NavMenuConfigCodecs$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Account/Account_NavMenuConfigCodecs.bs.js";
var toJson = Account_NavMenuConfigCodecs$ApiTypes.NavMenu.toJson;
var fromJson = Account_NavMenuConfigCodecs$ApiTypes.NavMenu.fromJson;
function mergeAttributes(settings, attributes) {
  return {
    topbarTarget: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuTopbarTarget;
    }), settings.topbarTarget),
    topbarTargetStyle: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuTopbarTargetStyle;
    }), settings.topbarTargetStyle),
    topbarZIndex: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuTopbarZIndex;
    }), settings.topbarZIndex),
    topbarContainerTarget: settings.topbarContainerTarget,
    topbarThreeColumnLayout: Belt_Option.orElse(Belt_Option.map(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuTopbarLayout;
    }), function (layout) {
      return layout === "three-column";
    }), settings.topbarThreeColumnLayout),
    topbarEnabled: settings.topbarEnabled,
    topbarHomeEnabled: settings.topbarHomeEnabled,
    hamburgerTarget: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuHamburgerTarget;
    }), settings.hamburgerTarget),
    hamburgerTargetStyle: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuHamburgerTargetStyle;
    }), settings.hamburgerTargetStyle),
    hamburgerContainerTarget: settings.hamburgerContainerTarget,
    hamburgerTriggerButtonTarget: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuHamburgerTriggerButtonTarget;
    }), settings.hamburgerTriggerButtonTarget),
    hamburgerZIndex: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuHamburgerZIndex;
    }), settings.hamburgerZIndex),
    hamburgerHomeEnabled: settings.hamburgerHomeEnabled,
    injectCSS: settings.injectCSS,
    injectJS: settings.injectJS,
    breakpointMaxWidth: Belt_Option.orElse(Belt_Option.flatMap(attributes, function (attr) {
      return attr.navMenuBreakpointMaxWidth;
    }), settings.breakpointMaxWidth)
  };
}
export { toJson, fromJson, mergeAttributes };
/* Account_NavMenuConfigCodecs-ApiTypes Not a pure module */