// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
import * as Webapi__Dom__Element from "../../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
function wipeDOMEvents(element) {
  var parentEl = element.parentElement;
  var childrenEl = Array.prototype.slice.call(element.childNodes);
  if (parentEl == null) {
    return element;
  }
  var clonedNode = element.cloneNode();
  Belt_Array.forEach(childrenEl, function (child) {
    clonedNode.appendChild(child);
  });
  parentEl.replaceChild(clonedNode, element);
  return Belt_Option.getWithDefault(Webapi__Dom__Element.ofNode(clonedNode), element);
}
function useWipeDOMEvents(element) {
  return React.useMemo(function () {
    var el = element.current;
    if (el == null) {
      return element;
    } else {
      return {
        current: Js_null_undefined.fromOption(Caml_option.some(wipeDOMEvents(el)))
      };
    }
  }, [element]);
}
function useWipeAndReplaceDOMEvents(element, newEventName, newEventFn) {
  var el = useWipeDOMEvents(element);
  React.useEffect(function () {
    Belt_Option.forEach(Caml_option.nullable_to_opt(el.current), function (el) {
      el.addEventListener(newEventName, newEventFn);
    });
    return function (param) {
      Belt_Option.forEach(Caml_option.nullable_to_opt(el.current), function (el) {
        el.removeEventListener(newEventName, newEventFn);
      });
    };
  }, []);
}
export { wipeDOMEvents, useWipeDOMEvents, useWipeAndReplaceDOMEvents };
/* react Not a pure module */