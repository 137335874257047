// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
function getKey(key) {
  if (key === "clientId") {
    return "mkr_client_id";
  }
  var tmp;
  if (typeof key === "object") {
    var variant = key.NAME;
    tmp = variant === "scrollRestoration" ? "scroll-" + key.VAL : variant === "history" ? "history" + Belt_Option.mapWithDefault(key.VAL, "", function (id) {
      return "-" + id;
    }) : variant === "favorites" ? "favorites" + Belt_Option.mapWithDefault(key.VAL, "", function (id) {
      return "-" + id;
    }) : variant === "selectedMarket" ? "selectedMarket-" + key.VAL : "cart-" + key.VAL;
  } else {
    tmp = key === "debuggerAllowed" ? "debuggerAllowed" : key === "navMenuConfig" ? "navMenuConfig" : key === "clientId" ? "clientId" : key === "gridColumns" ? "gridColumns" : "intro";
  }
  return "smartnav__v" + String(2) + "__" + tmp;
}
function get(key) {
  try {
    return Belt_Option.flatMap(Caml_option.null_to_opt(localStorage.getItem(getKey(key))), function (value) {
      if (value === "") {
        return;
      } else {
        return value;
      }
    });
  } catch (exn) {
    return;
  }
}
function set(key, value) {
  try {
    localStorage.setItem(getKey(key), value);
    return;
  } catch (exn) {
    return;
  }
}
function $$delete(key) {
  try {
    localStorage.removeItem(getKey(key));
    return;
  } catch (exn) {
    return;
  }
}
var version = 2;
export { version, getKey, get, set, $$delete };
/* No side effect */